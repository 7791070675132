import { render, staticRenderFns } from "./CeratometriaForm.vue?vue&type=template&id=5c0ccc10&scoped=true"
import script from "./CeratometriaForm.vue?vue&type=script&lang=js"
export * from "./CeratometriaForm.vue?vue&type=script&lang=js"
import style0 from "./CeratometriaForm.vue?vue&type=style&index=0&id=5c0ccc10&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0ccc10",
  null
  
)

export default component.exports