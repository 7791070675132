<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="ceratometria"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultation="hasAttendanceValues('preConsultation')"
    :hasExam="hasAttendanceValues('exam')"
  >
    <b-row>
      <b-col>
        <b-col>
          <label for="aparelho">Aparelho</label>
          <multiselect
            :value="aparelhoSelected"
            id="aparelho"
            label="name"
            :options="opcoesAparelho"
            :option-height="40"
            :showLabels="false"
            :showNoResults="true"
            :disabled="!canEdit"
            @select="value => onSelect('aparelho', value)"
            placeholder="Selecionar"
            class="with-border"
          >
          <template slot="noOptions"
            >Nenhum aparelho cadastrado
          </template>
            <template slot="noResult" slot-scope="props">
              <li @click="setAparelho(props.search)">
                <div class="multiselect__option custom-item">
                  <Plus class="icon" />
                  <p class="blue"> Adicionar aparelho "{{ props.search }}" </p>
                </div>
              </li>
            </template>
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
              <div class="new-ceratometria">
                <Icon tooltip="Limpa seleção">
                  <Close class="icon close" @click="clearFields" />
                </Icon>
                <Icon tooltip="Editar" v-if="showAddCustom && canEdit">
                    <Edit class="icon edit" @click="editCeratometria()" />
                </Icon>
                <Icon tooltip="Deletar"  v-if="showAddCustom && canEdit">
                  <Trash class="icon trash" @click="deleteCeratometria(aparelhoSelected?.id)" />
                </Icon>
              </div>
            </template>
          </multiselect>
        </b-col>
        <b-row> 
          <b-col cols="6">
            <div class="custom-input-group" >
              <div class="custom-input h-38">
                <div class="eye-area h-38" >
                  <EyeFill /> D
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span >Curvatura</span>
                </div>
                <div class="custom-input eye-box-container eye-box-container-corner eye-box-container-corner h-38 no-bb">
                  <span >Eixo</span>
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">
                  K1
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK1Curvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoK1Curvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-direito"
                    type="text"
                    class="form-control"
                    ref="olhoDireitoK1Curvatura"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK1Eixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoK1Eixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoK1Eixo"
                    type="text"
                    class="form-control no-br"
                  />
                </div>
              </div>
              <div class="custom-input no-bb ">
                <div class="eye-area no-br">
                  K2
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK2Curvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoK2Curvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoK2Curvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoK2Eixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoK2Eixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoK2Eixo"
                    type="text"
                    class="form-control no-br"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">
                  Cilindro
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoCilindroCurvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoCilindroCurvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoCilindroCurvatura"
                    type="text"
                    class="form-control"                  
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoCilindroEixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoCilindroEixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoCilindroEixo"
                    type="text"
                    class="form-control no-br"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">
                  Kmax (ápice)
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoDireitoKmax"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoDireitoKmax', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoDireitoKmax"
                    type="text"
                    class="form-control"                  
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area">
                  Impressão
                </div>
                <div class="custom-input no-bb">
                  <TextArea
                    id="DireitoImpressao"
                    :value="form.fields.DireitoImpressao"
                    @blur="updateMedicalRecord(form)"
                    @inputTextArea="el => updateForm('DireitoImpressao', el)"
                    :readonly="!canEdit"
                    rows="1"
                    class="form-control border-text"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="custom-input-group" >
              <div class="custom-input h-38">
                <div class="eye-area h-38" >
                  <EyeFill /> E
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Curvatura</span>
                </div>
                <div class="custom-input eye-box-container eye-box-container-corner eye-box-container-corner h-38 no-bb">
                  <span >Eixo</span>
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">
                  K1
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK1Curvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoK1Curvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-esquerdo"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK1Eixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoK1Eixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoK1Eixo"
                    type="text"
                    class="form-control no-br"                   
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area no-br">
                  K2
                </div>
                <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK2Curvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoK2Curvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoK2Curvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoK2Eixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoK2Eixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoK2Eixo"
                    type="text"
                    class="form-control no-br"                   
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area no-br">
                     Cilindro
                  </div>

                  <div class="custom-input no-bb with-br">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoCilindroCurvatura"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoCilindroCurvatura', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoCilindroCurvatura"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoCilindroEixo"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoCilindroEixo', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoCilindroEixo"
                    type="text"
                    class="form-control no-br"                   
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area no-br">
                     Kmax (ápice)
                  </div>

                  <div class="custom-input no-bb">
                  <input
                    autocomplete="off"
                    :value="form.fields.olhoEsquerdoKmax"
                    :readonly="!canEdit"
                    @input="el => updateForm('olhoEsquerdoKmax', el.target.value)"
                    @blur="updateMedicalRecord(form)"
                    id="ceratometria-olhoEsquerdoKmax"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="custom-input no-bb">
                <div class="eye-area">
                  Impressão
                </div>
                <div class="custom-input no-bb">
                  <TextArea
                    id="EsquerdoImpressao"
                    :value="form.fields.EsquerdoImpressao"
                    @blur="updateMedicalRecord(form)"
                    @inputTextArea="el => updateForm('EsquerdoImpressao', el)"
                    :readonly="!canEdit"
                    rows="1"
                    class="form-control border-text"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <CeratometriaModal
    :data="aparelhoSelected"
    @setCeratometria="setCeratometria"
    >
    </CeratometriaModal>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: { 
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    TextArea: () => import('@/components/General/TextArea'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Icon: () => import('@/components/General/Icon'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    CeratometriaModal: () => import('@/components/Attendance/Forms/Components/CeratometriaModal.vue'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.ceratometria,
      attendance: state => state.attendance.attendance,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation', 'exam']),
    showAddCustom() {
      return this.user.id === this.aparelhoSelected?.professional_id
    },
    aparelhoSelected() {
      return this.opcoesAparelho.find(aparelho => aparelho.name === this.form.fields.aparelho)
    }
  },
  async mounted() {
    await this.getCeratometriaOptions()
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      opcoesAparelho: [],
    }
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/ceratometria', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    async importAttendanceData(medicalRecords = null, type) {
      if (!this.hasAttendanceValues(type)) return
      await this.simpleImportData(medicalRecords, type)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    onSelect(key, value) {
      this.updateForm(key, value.name)
      this.updateMedicalRecord(this.form)
    },
    setAparelho(value) {
      if (!value.length) return;
      this.opcoesAparelho.push(value);
      this.updateForm('aparelho', value)
      this.updateMedicalRecord(this.form)
      this.saveCeratometria(value)
    },
    async saveCeratometria(value) {
      try {
        const response = await this.api.storeCeratometria({
          professional_id: this.user.id,
          name: value,
        })
        await this.getCeratometriaOptions()
        this.$toast.success('Aparelho para Ceratometria adcionado com sucesso!')
        return response
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getCeratometriaOptions(){
      try {
        const response = await this.api.getCeratometriaOptions(this.user.id)
        this.opcoesAparelho = response.data
        this.opcoesAparelho = response.data.map(note => {
          return {
            id: note.id,
            professional_id: note.professional_id,
            name: note.name
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    editCeratometria() {
      this.$refs.olhoDireitoK1Curvatura.focus()
      this.$bvModal.show('open-ceratometria-modal')
    },
    async deleteCeratometria(value) {
      try {
        await this.api.deleteCeratometria(value)
        await this.getCeratometriaOptions()
        await this.clearFields()
        await this.updateForm()
      } catch (error) {
        this.$toast.error(error.message)
      } 
    },
    clearFields() {
      this.form.fields.aparelho = null
      this.updateForm()
      this.updateMedicalRecord(this.form)
    },
    setCeratometria(ceratometria) {
      const index = this.opcoesAparelho.findIndex(value => value.id === ceratometria.id)
      this.opcoesAparelho.splice(index, 1, ceratometria)
      this.onSelect('aparelho', ceratometria)
    },
  }
}
</script>

<style lang="scss" scoped>
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      &.h-38 {
      height: 38px;
      }
      .form-control {
        border-radius: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 1 solid var(--neutral-300) !important;
      }

      .border-text {
        border-bottom: 0px !important;
        border-radius: 0px 0px 10px 0px !important;
      }
      .eye-area {
        width: 140px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &.no-br {
          border-radius: 0 !important;
          border-bottom: 1px solid var(--neutral-300);
        }

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      &.with-br{
        border-right: 1px solid var(--neutral-300);
      }
      &.bbr {
          border-radius: 0 0 8px 0 !important;
        }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }

  .eye-box-container {
    background-color: var(--neutral-100);
    align-items: center;

    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }

  .eye-box-container-corner {
    border-radius: 0 8px 0 0;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500)
  }

  .new-ceratometria {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    padding-right: 30px;

    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }

      &.close {
        fill: var(--type-active);
      }
    }
  }

  .blue {
    color: var(--blue-500);
    display: inline-block;
  }
</style>
